import { Navigate } from "react-router-dom";
import { GuardProps } from "../../types/auth";
import { EMPLOYEE_APP_DEFAULT_PATH } from "config";
import { useSelector } from "store";
import { getAuthInfo } from "selectors/auth";
import { ORGANISATION_TYPE } from "constants/General";

const SeminarGuard = ({ children }: GuardProps) => {
  const auth = useSelector(state => getAuthInfo(state));

if (auth.user?.OrganisationType?.toLowerCase() !== ORGANISATION_TYPE.NHS?.toLowerCase()) {
    return children;
  } else {
    return <Navigate to={EMPLOYEE_APP_DEFAULT_PATH} replace />;
  }
};

export default SeminarGuard;
